import React, { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Button, createTheme, ThemeProvider } from "@mui/material";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import { getWarehouseListAction } from "../Action/action";
import Swal from "sweetalert2";
import config from "../coreFIles/config";

const MagazzinoDettaglio = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [warehouse, setWarehouse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadWarehouseDetails = async () => {
      setIsLoading(true);
      try {
        const response = await getWarehouseListAction();
        if (response.data.success) {
          const foundWarehouse = response.data.data.find(w => w.id === parseInt(id));
          if (foundWarehouse) {
            setWarehouse(foundWarehouse);
          } else {
            Swal.fire("Errore", "Magazzino non trovato", "error");
            navigate("/magazzini");
          }
        }
      } catch (error) {
        console.error("Error loading warehouse details:", error);
        Swal.fire("Errore", "Si è verificato un errore durante il caricamento dei dettagli", "error");
      } finally {
        setIsLoading(false);
      }
    };

    loadWarehouseDetails();
  }, [id, navigate]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "name",
        header: "Nome Prodotto",
      },
      {
        accessorKey: "image",
        header: "Immagine",
        Cell: ({ row }) => (
          <a target="_blank" href={`${config.imageUrl}${row.original.image}`} rel="noreferrer">
            <img 
              src={`${config.imageUrl}${row.original.image}`} 
              width="70px" 
              height="70px" 
              alt=""
            />
          </a>
        ),
      },
      {
        header: "Prezzo di Collecto",
        Cell: ({ row }) => `€ ${(parseFloat(row.original.share_quantity) * parseFloat(row.original.share_price)).toLocaleString('it-IT', { minimumFractionDigits: 2 })}`,
    },
      {
        accessorKey: "paid_amount",
        header: "Prezzo di Acquisto",
        Cell: ({ cell }) => `€ ${parseFloat(cell.getValue()).toLocaleString('it-IT', { minimumFractionDigits: 2 })}`,
      },
      {
        accessorKey: "status",
        header: "Venduto",
        Cell: ({ row }) => (
          <span className={`badge ${row.original.pre_order_target === 1 ? 'bg-success' : 'bg-danger'}`}>
            {row.original.pre_order_target === 1 ? 'Venduto' : 'Non Venduto'}
          </span>
        ),
      },
      {
        accessorKey: "warehouse_id",
        header: "Stato",
        Cell: ({ row }) => (
          <span className={`badge ${row.original.warehouse_id ? 'bg-success' : 'bg-warning'}`}>
            {row.original.warehouse_id ? 'Consegnato' : 'Non consegnato'}
          </span>
        ),
      },
      {
        accessorKey: "paid",
        header: "Pagamento",
        Cell: ({ row }) => (
          <span className={`badge ${row.original.paid ? 'bg-success' : 'bg-warning'}`}>
            {row.original.paid ? 'Pagato' : 'Non pagato'}
          </span>
        ),
      },
      {
        accessorKey: "actions",
        header: "Azioni",
        enableSorting: false,
        Cell: ({ row }) => (
          <a href={`${config.baseUrl}itemAdd/${row.original.id}`} className="btn btn-primary btn-sm">
          Modifica
        </a>
        ),
      }
    ],
    []
  );

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: warehouse?.items || [],
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: true,
    muiTableBodyRowProps: { hover: false },
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    state: {
      isLoading,
    },
  });

  if (isLoading) {
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="box">
                    <div className="box-body">
                      Caricamento...
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  if (!warehouse) {
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="box">
                    <div className="box-body">
                      Caricamento...
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="wrapper">
      <Header />
      <Sidebar />
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-12">
              <div className="box">
                <div className="box-header with-border">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="box-title">Dettaglio Magazzino</h4>
                  </div>
                </div>

                <div className="box-body">
                  <div className="warehouse-details mb-4 p-4" style={{ backgroundColor: "#131720", borderRadius: "4px" }}>
                    <div className="row">
                      <div className="col-md-6">
                        <h5 className="mb-3">Informazioni Generali</h5>
                        <p><strong>Nome:</strong> {warehouse.name}</p>
                        <p><strong>Referente:</strong> {warehouse.contact_person}</p>
                        <p><strong>Codice Fiscale:</strong> {warehouse.fiscal_code || "-"}</p>
                        <p><strong>IBAN:</strong> {warehouse.iban || "-"}</p>
                        <p><strong>Numero Documento:</strong> {warehouse.document_number || "-"}</p>
                        <p>
                          <strong>Tipo Documento:</strong> {
                            warehouse.document_type === "passport" ? "Passaporto" :
                            warehouse.document_type === "identity_card" ? "Carta d'identità" :
                            warehouse.document_type === "driving_license" ? "Patente" :
                            "-"
                          }
                        </p>
                      </div>
                      <div className="col-md-6">
                        <h5 className="mb-3">Indirizzo</h5>
                        <p><strong>Via:</strong> {warehouse.street}, {warehouse.street_number}</p>
                        <p><strong>Città:</strong> {warehouse.city}</p>
                        <p><strong>Provincia:</strong> {warehouse.province}</p>
                      </div>
                    </div>
                    <div className="col-md-12 mt-4">
                      <h5 className="mb-3">Documenti</h5>
                      {warehouse.warehouse_documents && warehouse.warehouse_documents.length > 0 ? (
                        <div className="documents-list">
                          <div className="row">
                            {warehouse.warehouse_documents.map((doc, index) => (
                              <div key={index} className="col-md-2 col-sm-4 mb-4 text-center">
                                <a
                                  href={doc.file_path.toLowerCase().includes('pdf') ? 
                                    `${config.apiUrl}/itemPdf/${doc.file_path}` :
                                    `${config.imageUrl}${doc.file_path}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ 
                                    textDecoration: "none",
                                    display: "block"
                                  }}
                                >
                                  <div 
                                    className="document-icon mb-2"
                                    style={{
                                      backgroundColor: "#131720",
                                      border: "1px solid #ffffff1f", 
                                      borderRadius: "8px",
                                      padding: "20px",
                                      marginBottom: "10px"
                                    }}
                                  >
                                    {doc.file_path.toLowerCase().includes('pdf') ? (
                                      <i 
                                        className="fa fa-file-pdf-o"
                                        style={{
                                          fontSize: "40px",
                                          color: "#a2a4a9"
                                        }}
                                      ></i>
                                    ) : (
                                      <img
                                        src={`${config.imageUrl}${doc.file_path}`}
                                        alt="Preview"
                                        style={{
                                          maxWidth: "100%",
                                          maxHeight: "100px",
                                          objectFit: "contain"
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div 
                                    className="document-info"
                                    style={{
                                      color: "#a2a4a9",
                                      fontSize: "12px"
                                    }}
                                  >
                                    <div className="document-type mb-1">
                                      {doc.type === 'contract' ? 'Contratto' : 
                                       doc.type === 'visura' ? 'Visura' : 'Altro'}
                                    </div>
                                    <div 
                                      className="document-name"
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        maxWidth: "100%"
                                      }}
                                    >
                                      {doc.file_path}
                                    </div>
                                  </div>
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <p>Nessun documento disponibile</p>
                      )}
                    </div>
                  </div>

                  <div className="products-section mt-4">
                    <h5 className="mb-3">Prodotti nel Magazzino</h5>
                    <ThemeProvider theme={tableTheme}>
                      <MaterialReactTable table={table} />
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default MagazzinoDettaglio;
