import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import config from "./coreFIles/config";

import Login from "./component/login";
import Dashboard from "./component/dashboard";
import Changepassword from "./component/changepassword";
import Category from "./component/category";
import Addcategory from "./component/addcategory";
import Categoryupdate from "./component/updatecategory";
import ItemList from "./component/itemList";
import ItemAdd from "./component/itemAdd";
import Attributes from "./component/attributes";
import Addattributes from "./component/addattributes";
import Attributeupdate from "./component/updateattribute";
import AlertRequest from "./component/alert_request";
import BuyTrxExternal from "./component/buyTrxExternal";
import SellTrxExternal from "./component/sellTrxExternal";
import Notification from "./component/Notification";
import UserGuide from "./component/UserGuide";
import ExpertDetails from "./component/ExpertDetails";
import Withdraw from "./component/Withdraw";
import Refunds from "./component/Refunds";
import Referrals from "./component/referrals";
import PromoModal from "./component/promoModal";
import UserActions from "./component/userActions";
import GiftCards from "./component/giftCards";
import TransactionsV2 from "./component/transactionsV2";
import UsersV2 from "./component/usersV2";
import UserDetail from "./component/userDetail";
import Magazzini from "./component/Magazzini";
import Fornitori from "./component/Fornitori";
import FornitoreDettaglio from "./component/FornitoreDettaglio";
import MagazzinoDettaglio from "./component/MagazzinoDettaglio";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path={`${config.baseUrl}`} element={<Login />} />
          <Route path={`${config.baseUrl}dashboard`} element={<Dashboard />} />
          <Route path={`${config.baseUrl}V2users`} element={<UsersV2 />} />
          <Route path={`${config.baseUrl}userDetails`} element={<UserDetail />} />
          <Route path={`${config.baseUrl}changepassword`} element={<Changepassword />} />
          <Route path={`${config.baseUrl}Category`} element={<Category />} />
          <Route path={`${config.baseUrl}Addcategory`} element={<Addcategory />} />
          <Route path={`${config.baseUrl}Categoryupdate/:id`} element={<Categoryupdate />} />
          <Route path={`${config.baseUrl}itemList`} element={<ItemList />} />
          <Route path={`${config.baseUrl}itemAdd`} element={<ItemAdd />} />
          <Route path={`${config.baseUrl}itemAdd/:id`} element={<ItemAdd />} />
          <Route path={`${config.baseUrl}attributes`} element={<Attributes />} />
          <Route path={`${config.baseUrl}Addattributes`} element={<Addattributes />} />
          <Route path={`${config.baseUrl}Addattributes/:category_id`} element={<Addattributes />} />
          <Route path={`${config.baseUrl}attributeupdate/:id`} element={<Attributeupdate />} />
          <Route path={`${config.baseUrl}alert_request`} element={<AlertRequest />} />
          <Route path={`${config.baseUrl}V2transactions`} element={<TransactionsV2 />} />
          <Route path={`${config.baseUrl}giftCards`} element={<GiftCards />} />
          <Route path={`${config.baseUrl}buyTrxExternal`} element={<BuyTrxExternal />} />
          <Route path={`${config.baseUrl}sellTrxExternal`} element={<SellTrxExternal />} />
          <Route path={`${config.baseUrl}notification`} element={<Notification />} />
          <Route path={`${config.baseUrl}userguide`} element={<UserGuide />} />
          <Route path={`${config.baseUrl}expertdetails`} element={<ExpertDetails />} />
          <Route path={`${config.baseUrl}withdraw`} element={<Withdraw />} />
          <Route path={`${config.baseUrl}refunds`} element={<Refunds />} />
          <Route path={`${config.baseUrl}referrals`} element={<Referrals />} />
          <Route path={`${config.baseUrl}promo`} element={<PromoModal />} />
          <Route path={`${config.baseUrl}userActions`} element={<UserActions />} />
          <Route path={`${config.baseUrl}magazzini`} element={<Magazzini />} />
          <Route path={`${config.baseUrl}fornitori`} element={<Fornitori />} />
          <Route path={`${config.baseUrl}fornitori/:id`} element={<FornitoreDettaglio />} />
          <Route path={`${config.baseUrl}magazzini/:id`} element={<MagazzinoDettaglio />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
