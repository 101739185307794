import React, { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Button, createTheme, ThemeProvider, Box } from "@mui/material";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import { getSupplierListAction } from "../Action/action";
import Swal from "sweetalert2";
import config from "../coreFIles/config";
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import firma from "../assets/firma.png"; // Assicurati che il percorso sia corretto

// Definisci gli stili per il PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 40,
    fontSize: 11,
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 20,
  },
  section: {
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: 11,
    fontWeight: "bold",
    marginBottom: 8,
    fontFamily: "Helvetica-Bold",
  },
  paragraph: {
    marginBottom: 8,
    lineHeight: 1.5,
  },
  bold: {
    fontWeight: "bold",
  },
  table: {
    marginVertical: 10,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    paddingVertical: 5,
  },
  tableCell: {
    flex: 1,
    paddingHorizontal: 5,
  },
  signatures: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 50,
  },
  signatureLine: {
    borderTopWidth: 1,
    borderTopColor: "#000",
    width: 200,
    marginTop: 40,
  },
  warehouseDetails: {
    marginLeft: 20,
    paddingLeft: 10,
    borderLeftWidth: 1,
    borderLeftColor: "#666",
    marginVertical: 5,
  },
  warehouseTitle: {
    fontSize: 10,
    fontWeight: "bold",
    marginBottom: 3,
  },
  warehouseItem: {
    fontSize: 9,
    marginBottom: 2,
    color: "#444",
  },
  signature: {
    width: 150,
    height: 50,
    marginLeft: -30,
  },
});

// Componente PDF
const PDFDocument = ({ supplier, selectedProducts }) => {
  const today = new Date().toLocaleDateString("it-IT");
  const totalAmount = selectedProducts.reduce((sum, product) => sum + parseFloat(product.paid_amount), 0);
  selectedProducts = selectedProducts.map((product) => {
    try {
      return {
        ...product,
        warehouse_details: JSON.parse(product.warehouse_details),
      };
    } catch (error) {
      return {
        ...product,
        warehouse_details: [],
      };
    }
  });
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>Contratto di Cessione di Beni</Text>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>TRA</Text>
          <Text style={styles.paragraph}>
            <Text style={styles.bold}>{supplier.name}</Text>, {supplier.type === "private" ? "Privato" : "Impresa"},{supplier.fiscal_code && ` C.F. ${supplier.fiscal_code},`} con
            sede in {supplier.street} {supplier.street_number}, {supplier.city} {supplier.province},{supplier.contact_person && ` in persona di ${supplier.contact_person},`}
          </Text>
          <Text style={styles.sectionTitle}>E</Text>

          <Text style={styles.paragraph}>
            <Text style={styles.bold}>COLLECTO SRL</Text> 13062490969, in persona del legale rappresentante pro tempore vigente, con sede legale Milano (MI), Via Paleocapa n.6, in
            prosieguo denominato "Collecto"
          </Text>
          <Text style={styles.paragraph}>Di seguito congiuntamente denominate 'le Parti'.</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Premesse</Text>
          <Text style={styles.paragraph}>
            COLLECTO SRL è una società operante nel settore della compravendita e dello scambio di beni di lusso connotati da autenticità e unicità. Il Cedente dichiara di voler
            trasferire a Collecto la proprietà dei beni descritti di seguito.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Articolo 1 – Oggetto della Cessione</Text>
          <Text style={styles.paragraph}>Il Cedente cede, a titolo definitivo, i seguenti beni al Cessionario:</Text>

          <View style={styles.table}>
            {selectedProducts.map((product, i) => (
              <View key={i}>
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, { flex: 2 }]}>{product.name}</Text>
                  <Text style={styles.tableCell}>€ {parseFloat(product.paid_amount).toLocaleString("it-IT", { minimumFractionDigits: 2 })}</Text>
                </View>

                {/* Aggiungi i dettagli del magazzino se presenti */}
                {product.warehouse_details && product.warehouse_details.length > 0 && (
                  <View style={styles.warehouseDetails}>
                    {product.warehouse_details.map((detail, index) => (
                      <Text key={index} style={styles.warehouseItem}>
                        • ID: {detail.id || "-"} - Lotto: {detail.name || "-"}
                      </Text>
                    ))}
                  </View>
                )}
              </View>
            ))}
            <View style={[styles.tableRow]}>
              <Text style={[styles.tableCell, { flex: 2, fontWeight: "bold" }]}>Totale</Text>
              <Text style={[styles.tableCell, { fontWeight: "bold" }]}>€ {totalAmount.toLocaleString("it-IT", { minimumFractionDigits: 2 })}</Text>
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Articolo 2 – Prezzo della Cessione</Text>
          <Text style={styles.paragraph}>
            Il prezzo totale dei beni ceduti è indicato in corrispondenza di ciascun bene e sarà versato secondo le modalità concordate tra le Parti.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Articolo 3 – Dichiarazioni del Cedente</Text>
          <Text style={styles.paragraph}>Il Cedente dichiara di essere il legittimo proprietario dei beni ceduti e che essi sono liberi da qualsiasi vincolo o gravame.</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Articolo 4 – Efficacia della Cessione</Text>
          <Text style={styles.paragraph}>La cessione dei beni ha efficacia immediata alla firma del presente documento.</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Articolo 5 – Garanzia di Autenticità del Bene</Text>
          <Text style={styles.paragraph}>
            Il Cedente garantisce che i beni ceduti sono autentici in ogni loro parte e che non vi sono elementi che possano comprometterne l'integrità o l'originalità. Il Cedente,
            inoltre, si impegna a fornire eventuali certificazioni di autenticità, se disponibili, a supporto della garanzia fornita.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.paragraph}>Milano, lì {today}</Text>
        </View>

        <View style={styles.signatures}>
          <View>
            <Text>Il Cedente</Text>
            <View style={styles.signatureLine} />
          </View>
          <View>
            <Text>COLLECTO Srl</Text>
            <Image source={firma} style={styles.signature} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

const FornitoreDettaglio = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [supplier, setSupplier] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    const loadSupplierDetails = async () => {
      setIsLoading(true);
      try {
        const response = await getSupplierListAction();
        if (response.success) {
          const foundSupplier = response.data.find((s) => s.id === parseInt(id));
          if (foundSupplier) {
            setSupplier(foundSupplier);
          } else {
            Swal.fire("Errore", "Fornitore non trovato", "error");
            navigate("/fornitori");
          }
        }
      } catch (error) {
        console.error("Error loading supplier details:", error);
        Swal.fire("Errore", "Si è verificato un errore durante il caricamento dei dettagli", "error");
      } finally {
        setIsLoading(false);
      }
    };

    loadSupplierDetails();
  }, [id, navigate]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Nome Prodotto",
      },
      {
        accessorKey: "image",
        header: "Immagine",
        Cell: ({ row }) => (
          <a target="_blank" href={`${config.imageUrl}${row.original.image}`} rel="noreferrer">
            <img src={`${config.imageUrl}${row.original.image}`} width="70px" height="70px" alt="" />
          </a>
        ),
      },
      {
        header: "Prezzo Collecto",
        Cell: ({ row }) => `€ ${(parseFloat(row.original.share_quantity) * parseFloat(row.original.share_price)).toLocaleString("it-IT", { minimumFractionDigits: 2 })}`,
      },
      {
        accessorKey: "paid_amount",
        header: "Prezzo di Acquisto",
        Cell: ({ cell }) => `€ ${parseFloat(cell.getValue()).toLocaleString("it-IT", { minimumFractionDigits: 2 })}`,
      },
      {
        accessorKey: "status",
        header: "Venduto",
        Cell: ({ row }) => (
          <span className={`badge ${row.original.pre_order_target === 1 ? "bg-success" : "bg-danger"}`}>{row.original.pre_order_target === 1 ? "Venduto" : "Non Venduto"}</span>
        ),
      },
      {
        accessorKey: "warehouse_id",
        header: "Stato",
        Cell: ({ row }) => (
          <span className={`badge ${row.original.warehouse_id ? "bg-success" : "bg-warning"}`}>{row.original.warehouse_id ? "Consegnato" : "Non consegnato"}</span>
        ),
      },
      {
        accessorKey: "paid",
        header: "Pagamento",
        Cell: ({ row }) => <span className={`badge ${row.original.paid ? "bg-success" : "bg-warning"}`}>{row.original.paid ? "Pagato" : "Non pagato"}</span>,
      },
      {
        accessorKey: "actions",
        header: "Azioni",
        enableSorting: false,
        Cell: ({ row }) => (
          <a href={`${config.baseUrl}itemAdd/${row.original.id}`} className="btn btn-primary btn-sm">
            Modifica
          </a>
        ),
      },
    ],
    []
  );

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  const handleBulkAction = () => {
    const selectedRows = table.getSelectedRowModel().flatRows;
    const selectedIds = selectedRows.map((row) => row.original.id);

    // Qui puoi implementare la tua logica per l'azione bulk
    console.log("Selected IDs:", selectedIds);

    // Esempio di conferma con SweetAlert2
    Swal.fire({
      title: "Conferma azione",
      text: `Vuoi procedere con l'azione su ${selectedRows.length} prodotti selezionati?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Procedi",
      cancelButtonText: "Annulla",
    }).then((result) => {
      if (result.isConfirmed) {
        // Implementa qui la tua action
      }
    });
  };

  const table = useMaterialReactTable({
    columns,
    data: supplier?.items || [],
    enableRowSelection: true,
    state: {
      rowSelection,
      isLoading,
    },
    onRowSelectionChange: setRowSelection,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: true,
    muiTableBodyRowProps: { hover: false },
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const selectedRows = table.getSelectedRowModel().flatRows;
      const selectedProducts = selectedRows.map((row) => row.original);

      return (
        <Box sx={{ display: "flex" }}>
          {selectedRows.length > 0 ? (
            <PDFDownloadLink
              document={<PDFDocument supplier={supplier} selectedProducts={selectedProducts} />}
              fileName={`${supplier.name}_prodotti_${new Date().toISOString().split("T")[0]}.pdf`}
              style={{ textDecoration: "none" }}
            >
              {({ loading }) => (
                <Button color="primary" variant="contained" disabled={loading}>
                  {loading ? "Generazione PDF..." : `Genera PDF (${selectedRows.length})`}
                </Button>
              )}
            </PDFDownloadLink>
          ) : (
            <>
              {/* <Button color="primary" disabled variant="contained">
              Seleziona prodotti
            </Button> */}
            </>
          )}
        </Box>
      );
    },
  });

  if (isLoading) {
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="box">
                    <div className="box-body">Caricamento...</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  if (!supplier) {
    return null;
  }

  return (
    <div className="wrapper">
      <Header />
      <Sidebar />
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-12">
              <div className="box">
                <div className="box-header with-border">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="box-title">Dettaglio Fornitore</h4>
                  </div>
                </div>

                <div className="box-body">
                  <div className="supplier-details mb-4 p-4" style={{ backgroundColor: "#131720", borderRadius: "4px" }}>
                    <div className="row">
                      <div className="col-md-6">
                        <h5 className="mb-3">Informazioni Generali</h5>
                        <p>
                          <strong>Nome/Ragione Sociale:</strong> {supplier.name}
                        </p>
                        <p>
                          <strong>Tipologia:</strong> {supplier.type === "private" ? "Privato" : "Impresa"}
                        </p>
                        <p>
                          <strong>Referente:</strong> {supplier.contact_person}
                        </p>
                        <p>
                          <strong>Codice Fiscale:</strong> {supplier.fiscal_code || "-"}
                        </p>
                        <p>
                          <strong>IBAN:</strong> {supplier.iban || "-"}
                        </p>
                        <p>
                          <strong>Numero Documento:</strong> {supplier.document_number || "-"}
                        </p>
                        <p>
                          <strong>Tipo Documento:</strong>{" "}
                          {supplier.document_type === "passport"
                            ? "Passaporto"
                            : supplier.document_type === "identity_card"
                            ? "Carta d'identità"
                            : supplier.document_type === "driving_license"
                            ? "Patente"
                            : "-"}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <h5 className="mb-3">Indirizzo</h5>
                        <p>
                          <strong>Via:</strong> {supplier.street}, {supplier.street_number}
                        </p>
                        <p>
                          <strong>Città:</strong> {supplier.city}
                        </p>
                        <p>
                          <strong>Provincia:</strong> {supplier.province}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 mt-4">
                      <h5 className="mb-3">Documenti</h5>
                      {supplier.warehouse_documents && supplier.warehouse_documents.length > 0 ? (
                        <div className="documents-list">
                          <div className="row">
                            {supplier.warehouse_documents.map((doc, index) => (
                              <div key={index} className="col-md-2 col-sm-4 mb-4 text-center">
                                <a
                                  href={doc.file_path.toLowerCase().includes("pdf") ? `${config.apiUrl}/itemPdf/${doc.file_path}` : `${config.imageUrl}${doc.file_path}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    textDecoration: "none",
                                    display: "block",
                                  }}
                                >
                                  <div
                                    className="document-icon mb-2"
                                    style={{
                                      backgroundColor: "#131720",
                                      border: "1px solid #ffffff1f",
                                      borderRadius: "8px",
                                      padding: "20px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    {doc.file_path.toLowerCase().includes("pdf") ? (
                                      <i
                                        className="fa fa-file-pdf-o"
                                        style={{
                                          fontSize: "40px",
                                          color: "#a2a4a9",
                                        }}
                                      ></i>
                                    ) : (
                                      <img
                                        src={`${config.imageUrl}${doc.file_path}`}
                                        alt="Document preview"
                                        style={{
                                          width: "100%",
                                          height: "auto",
                                          maxHeight: "100px",
                                          objectFit: "contain",
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div
                                    className="document-info"
                                    style={{
                                      color: "#a2a4a9",
                                      fontSize: "12px",
                                    }}
                                  >
                                    <div className="document-type mb-1">{doc.type === "contract" ? "Contratto" : doc.type === "visura" ? "Visura" : "Altro"}</div>
                                    <div
                                      className="document-name"
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        maxWidth: "100%",
                                      }}
                                    >
                                      {doc.file_path}
                                    </div>
                                  </div>
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <p>Nessun documento disponibile</p>
                      )}
                    </div>
                  </div>

                  <div className="products-section mt-4">
                    <h5 className="mb-3">Prodotti del Fornitore</h5>
                    <ThemeProvider theme={tableTheme}>
                      <MaterialReactTable table={table} />
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default FornitoreDettaglio;
