import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Toaster } from "react-hot-toast";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Button, createTheme, Drawer, ThemeProvider, useMediaQuery } from "@mui/material";
import Swal from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { addWarehouseAction, editWarehouseAction, getWarehouseListAction, deleteWarehouseAction } from "../Action/action";
import config from "../coreFIles/config";
import { useNavigate } from "react-router-dom";

const Magazzini = () => {
  const [data, setData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editingWarehouse, setEditingWarehouse] = useState(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [deletedDocuments, setDeletedDocuments] = useState([]);
  const navigate = useNavigate();

  const { control, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: {
      type: "private",
      name: "",
      contact_person: "",
      city: "",
      province: "",
      street: "",
      street_number: "",
      fiscal_code: "",
      iban: "",
      document_number: "",
      document_type: "",
      documents: [],
    },
  });

  // Reuse the same onDrop logic from Fornitori
  const onDrop = useCallback(
    (acceptedFiles, fieldName) => {
      const processFile = (file) => {
        if (file.type.startsWith("image/")) {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve({ file, preview: reader.result });
            };
            reader.readAsDataURL(file);
          });
        } else {
          return Promise.resolve({ file, preview: null });
        }
      };

      Promise.all(acceptedFiles.map(processFile)).then((processedFiles) => {
        if (fieldName === "documents.other") {
          setValue(fieldName, (prevFiles) => [...(prevFiles || []), ...processedFiles]);
        } else {
          setValue(fieldName, processedFiles[0]);
        }
      });
    },
    [setValue]
  );

  // Load warehouses on component mount
  useEffect(() => {
    const loadWarehouses = async () => {
      try {
        const response = await getWarehouseListAction();
        console.log(response);
        if (response.data.success) {
          setData(response.data.data);
        }
      } catch (error) {
        console.error("Error loading warehouses:", error);
        Swal.fire("Errore", "Si è verificato un errore durante il caricamento dei magazzini", "error");
      }
    };

    loadWarehouses();
  }, []);

  // Form submission handler
  const onSubmit = async (formData) => {
    try {
      const data = new FormData();

      // Add basic warehouse info
      data.append("type", formData.type);
      data.append("name", formData.name);
      data.append("contact_person", formData.contact_person);
      data.append("city", formData.city);
      data.append("province", formData.province);
      data.append("street", formData.street);
      data.append("street_number", formData.street_number);
      data.append("fiscal_code", formData.fiscal_code);
      data.append("iban", formData.iban);
      data.append("document_number", formData.document_number);
      data.append("document_type", formData.document_type);

      // Filtra solo i nuovi documenti (quelli non readonly)
      const newDocuments = formData.documents.filter((doc) => !doc.readonly);

      // Prepara gli array per i file, i tipi e i nomi
      const files = [];
      const types = [];
      const fileNames = [];

      newDocuments.forEach((doc) => {
        if (doc.file) {
          files.push(doc.file);
          types.push(doc.type);
          fileNames.push(doc.file.name);
        }
      });

      // Appendiamo ogni file e tipo separatamente
      files.forEach((file, index) => {
        data.append("files", file);
      });

      types.forEach((type) => {
        data.append("fileTypes", type);
      });

      // Aggiungiamo i nomi dei file
      data.append("fileNames", JSON.stringify(fileNames));

      // Aggiungiamo anche il conteggio totale dei nuovi file
      data.append("totalFiles", files.length);

      // Aggiungi l'array dei documenti eliminati
      if (deletedDocuments.length > 0) {
        data.append("deletedDocuments", JSON.stringify(deletedDocuments));
      }

      console.log("Files being sent:", files);
      console.log("Types being sent:", types);
      console.log("File names being sent:", fileNames);

      let response;
      if (editingWarehouse) {
        response = await editWarehouseAction(data, editingWarehouse.id);
      } else {
        response = await addWarehouseAction(data);
      }

      if (response.data.success) {
        Swal.fire("Success", editingWarehouse ? "Magazzino modificato con successo!" : "Magazzino aggiunto con successo!", "success");
        const updatedList = await getWarehouseListAction();
        setData(updatedList.data.data);
      } else {
        Swal.fire("Errore", response.data.message || "Si è verificato un errore", "error");
      }

      setIsDrawerOpen(false);
      setEditingWarehouse(null);
      reset();

      // Reset deletedDocuments dopo il salvataggio
      setDeletedDocuments([]);
    } catch (error) {
      console.error("Error submitting warehouse:", error);
      Swal.fire("Errore", "Si è verificato un errore durante il salvataggio", "error");
    }
  };

  // Table columns definition
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "type",
        header: "Tipologia",
        Cell: ({ cell }) => (cell.getValue() === "private" ? "Privato" : "Impresa"),
      },
      {
        accessorKey: "name",
        header: "Nome/Ragione Sociale",
      },
      {
        accessorKey: "contact_person",
        header: "Referente",
      },
      {
        header: "Indirizzo",
        accessorFn: (row) => `${row.street}, ${row.street_number}, ${row.city}, ${row.province}`,
      },
      {
        accessorKey: "id",
        header: "Azioni",
        Cell: ({ row }) => (
          <div className="d-flex align-items-center">
            <a 
              href={`${config.baseUrl}magazzini/${row.original.id}`}
              className="btn btn-sm btn-primary"
              style={{ marginRight: "10px", textDecoration: "none" }}
            >
              Dettaglio
            </a>
            <br />
            <br />
            <a className="btn btn-sm btn-primary" variant="contained" color="primary" onClick={(e) => {e.preventDefault(); handleEdit(row.original)}} style={{ marginRight: "10px" }}>
              Modifica
            </a>
            <br />
            <br />
            <a className="btn btn-sm btn-primary" variant="contained" color="error" onClick={(e) => {e.preventDefault(); handleDelete(row.original.id)}}>
              Elimina
            </a>
          </div>
        ),
      },
    ],
    []
  );

  const handleEdit = (warehouse) => {
    setEditingWarehouse(warehouse);
    setDeletedDocuments([]); // Reset deletedDocuments

    // Transform documents and mark them as readonly if they come from backend
    const formattedDocuments =
      warehouse.warehouse_documents?.map((doc) => ({
        id: doc.id, // Assicurati di includere l'ID del documento
        type: doc.type,
        file: { name: doc.file_path },
        readonly: true, // Add this flag for backend documents
      })) || [];

    reset({
      type: warehouse.type || "private",
      name: warehouse.name || "",
      contact_person: warehouse.contact_person || "",
      city: warehouse.city || "",
      province: warehouse.province || "",
      street: warehouse.street || "",
      street_number: warehouse.street_number || "",
      fiscal_code: warehouse.fiscal_code || "",
      iban: warehouse.iban || "",
      document_number: warehouse.document_number || "",
      document_type: warehouse.document_type || "",
      documents: formattedDocuments,
    });
    setIsDrawerOpen(true);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Sei sicuro?",
      text: "Questa azione non può essere annullata!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sì, elimina!",
      cancelButtonText: "Annulla",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteWarehouseAction(id);
          if (response.data.success) {
            const updatedList = await getWarehouseListAction();
            setData(updatedList.data.data);
            Swal.fire("Eliminato!", "Il magazzino è stato eliminato.", "success");
          } else {
            Swal.fire("Errore", response.data.message || "Si è verificato un errore durante l'eliminazione", "error");
          }
        } catch (error) {
          console.error("Error deleting warehouse:", error);
          Swal.fire("Errore", "Si è verificato un errore durante l'eliminazione", "error");
        }
      }
    });
  };

  const openFileInNewTab = (file) => {
    if (!file) return;

    if (file.preview) {
      window.open(file.preview, "_blank");
    } else if (file.file) {
      if (file.file instanceof File) {
        const fileUrl = URL.createObjectURL(file.file);
        window.open(fileUrl, "_blank");
      } else {
        const baseUrl = file.file.name.toLowerCase().includes('pdf') ? 
          `${config.apiUrl}/itemPdf/` :
          `${config.imageUrl}`;
        window.open(baseUrl + file.file.name, '_blank');
      }
    }
  };

  // Add table configuration
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: true,
    /* muiTableBodyRowProps: ({ row }) => ({
      hover: false,
      onClick: () => navigate(`/magazzini/${row.original.id}`),
      sx: { cursor: 'pointer' }
    }), */
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
  });

  // Return the complete UI
  return (
    <div className="wrapper">
      <Header />
      <Sidebar />
      <Toaster />
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-12">
              <div className="box">
                <div className="box-header with-border">
                  <h4 className="box-title">Magazzini</h4>
                  <button
                    className="btn btn-primary float-right"
                    onClick={() => {
                      setIsDrawerOpen(true);
                      setEditingWarehouse(null);
                      reset();
                    }}
                  >
                    Aggiungi Magazzino
                  </button>
                </div>
                <div className="box-body">
                  <ThemeProvider theme={tableTheme}>
                    <MaterialReactTable table={table} />
                  </ThemeProvider>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
          setEditingWarehouse(null);
          reset();
        }}
        PaperProps={{
          sx: {
            backgroundColor: "#1d2330",
            color: "#a2a4a9",
            width: isMobile ? "90%" : "50%",
          },
        }}
      >
        <div className="drawer-content p-4">
          <h5 className="drawer-title mb-4">{editingWarehouse ? "Modifica Magazzino" : "Aggiungi Magazzino"}</h5>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <div className="form-group">
                <label>Tipologia</label>
                <div style={{ marginTop: "10px" }}></div>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <select className="form-control" {...field} style={{ backgroundColor: "#131720", color: "#a2a4a9", border: "1px solid #ffffff1f" }}>
                      <option value="private">Privato</option>
                      <option value="company">Impresa</option>
                    </select>
                  )}
                />
              </div>
              <div className="form-group">
                <label>Nome/Ragione Sociale</label>
                <div style={{ marginTop: "10px" }}></div>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} style={{ backgroundColor: "#131720", color: "#a2a4a9", border: "1px solid #ffffff1f" }} />
                  )}
                />
              </div>
              <div className="form-group">
                <label>Referente</label>
                <div style={{ marginTop: "10px" }}></div>
                <Controller
                  name="contact_person"
                  control={control}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} style={{ backgroundColor: "#131720", color: "#a2a4a9", border: "1px solid #ffffff1f" }} />
                  )}
                />
              </div>
              <div className="form-group">
                <label>Indirizzo</label>
                <div style={{ marginTop: "10px" }}></div>
                <div className="row">
                  <div className="col-md-6">
                    <Controller
                      name="city"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Città"
                          {...field}
                          style={{ backgroundColor: "#131720", color: "#a2a4a9", border: "1px solid #ffffff1f" }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-6">
                    <Controller
                      name="province"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Provincia"
                          {...field}
                          style={{ backgroundColor: "#131720", color: "#a2a4a9", border: "1px solid #ffffff1f" }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-8">
                    <Controller
                      name="street"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Via"
                          {...field}
                          style={{ backgroundColor: "#131720", color: "#a2a4a9", border: "1px solid #ffffff1f" }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-4">
                    <Controller
                      name="street_number"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Civico"
                          {...field}
                          style={{ backgroundColor: "#131720", color: "#a2a4a9", border: "1px solid #ffffff1f" }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Codice Fiscale</label>
                <div style={{ marginTop: "10px" }}></div>
                <Controller
                  name="fiscal_code"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text"
                      className="form-control"
                      maxLength="16"
                      {...field}
                      style={{ backgroundColor: "#131720", color: "#a2a4a9", border: "1px solid #ffffff1f" }}
                    />
                  )}
                />
              </div>
              <div className="form-group">
                <label>IBAN</label>
                <div style={{ marginTop: "10px" }}></div>
                <Controller
                  name="iban"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text"
                      className="form-control"
                      maxLength="34"
                      {...field}
                      style={{ backgroundColor: "#131720", color: "#a2a4a9", border: "1px solid #ffffff1f" }}
                    />
                  )}
                />
              </div>
              <div className="form-group">
                <label>Numero Documento</label>
                <div style={{ marginTop: "10px" }}></div>
                <Controller
                  name="document_number"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text"
                      className="form-control"
                      maxLength="50"
                      {...field}
                      style={{ backgroundColor: "#131720", color: "#a2a4a9", border: "1px solid #ffffff1f" }}
                    />
                  )}
                />
              </div>
              <div className="form-group">
                <label>Tipo Documento</label>
                <div style={{ marginTop: "10px" }}></div>
                <Controller
                  name="document_type"
                  control={control}
                  render={({ field }) => (
                    <select 
                      className="form-control" 
                      {...field} 
                      style={{ backgroundColor: "#131720", color: "#a2a4a9", border: "1px solid #ffffff1f" }}
                    >
                      <option value="">Seleziona tipo documento</option>
                      <option value="passport">Passaporto</option>
                      <option value="identity_card">Carta d'identità</option>
                      <option value="driving_license">Patente</option>
                    </select>
                  )}
                />
              </div>
              <div className="form-group">
                <label>Documenti</label>
                <div style={{ marginTop: "10px" }}></div>

                <Controller
                  name="documents"
                  control={control}
                  render={({ field }) => (
                    <div>
                      {field.value.map((doc, index) => (
                        <div key={index} className="mb-3 p-3" style={{ border: "1px solid #ffffff1f", borderRadius: "4px" }}>
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <select
                                className="form-control"
                                value={doc.type}
                                onChange={(e) => {
                                  const newDocs = [...field.value];
                                  newDocs[index].type = e.target.value;
                                  field.onChange(newDocs);
                                }}
                                disabled={doc.readonly} // Manteniamo il select disabilitato
                                style={{ backgroundColor: "#131720", color: "#a2a4a9", border: "1px solid #ffffff1f" }}
                              >
                                <option value="contract">Contratto</option>
                                <option value="visura">Visura</option>
                                <option value="other">Altro</option>
                              </select>
                            </div>
                            <div className="col-md-6">
                              <div
                                className="dropzone"
                                style={{
                                  backgroundColor: "#131720",
                                  color: "#a2a4a9",
                                  border: "1px dashed #ffffff1f",
                                  padding: "10px",
                                  cursor: doc.readonly ? "default" : "pointer",
                                  opacity: doc.readonly ? 0.7 : 1,
                                }}
                                onClick={() => {
                                  if (doc.readonly) return;
                                  const input = document.createElement("input");
                                  input.type = "file";
                                  input.onchange = (e) => {
                                    if (e.target.files && e.target.files[0]) {
                                      const file = e.target.files[0];
                                      
                                      console.log("File selected:", file);
                                      const newDocs = [...field.value];
                                      newDocs[index] = {
                                        ...newDocs[index],
                                        file: file,
                                        preview: URL.createObjectURL(file),
                                      };
                                      field.onChange(newDocs);
                                    }
                                  };
                                  input.click();
                                }}
                              >
                                {doc.file ? (
                                  <p onClick={() => openFileInNewTab(doc)} style={{ cursor: "pointer", margin: 0 }}>
                                    {doc.file.name}
                                  </p>
                                ) : (
                                  <p style={{ margin: 0 }}>Clicca per selezionare un file</p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-2">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => {
                                  // Se il documento è readonly (dal backend) e ha un ID, aggiungerlo a deletedDocuments
                                  if (doc.readonly && doc.id) {
                                    setDeletedDocuments((prev) => [...prev, doc.id]);
                                  }
                                  const newDocs = field.value.filter((_, i) => i !== index);
                                  field.onChange(newDocs);
                                }}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}

                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          field.onChange([...field.value, { type: "contract", file: null, readonly: false }]);
                        }}
                      >
                        <i className="fa fa-plus"></i> Aggiungi documento
                      </button>
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setIsDrawerOpen(false);
                  setEditingWarehouse(null);
                  reset();
                }}
              >
                Chiudi
              </button>
              <button type="submit" className="btn btn-primary">
                {editingWarehouse ? "Modifica" : "Salva"}
              </button>
            </div>
          </form>
        </div>
      </Drawer>

      <Footer />
    </div>
  );
};

export default Magazzini;
