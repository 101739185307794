import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import moment from "moment";
import config from "../coreFIles/config";

const UserItemsTable = ({ itemAdditions, isLoading, isError, isRefetching }) => {
  const calculateTotalValue = () => {
    return itemAdditions.reduce((total, item) => total + (item.item.share_price * item.count), 0);
  };

  const table = useMaterialReactTable({
    columns: [
      {
        accessorKey: "item.name",
        header: "Item Name",
        size: 50,
      },
      {
        accessorKey: "item.category.category_name",
        header: "Category",
        size: 30,
      },
      {
        accessorKey: "count",
        header: "Amount",
        size: 20,
      },
      {
        header: "Total",
        size: 20,
        accessorFn: (row) => row.item.share_price * row.count,
      },
    ],
    data: itemAdditions || [],
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: {
      pagination: { pageSize: 10, pageIndex: 0 },
      sorting: [{ id: "update_date", desc: true }],
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
    },
  });

  return (
    <div>
      <div style={{ fontSize: '18px', color: '#a2a4a9' }}>
        Total Value: {calculateTotalValue().toFixed(2)}
      </div>
      <MaterialReactTable table={table} />
    </div>
  );
};

export default UserItemsTable;
