import React, { useEffect, useState, useCallback } from "react";
import BigNumber from "bignumber.js";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert2";

import {
  getCategoryListAction,
  addItemAction,
  getAttributebyidListAction,
  getexpertlistAction,
  getUsersListAction,
  getUsdcPrice,
  getWarehouseListAction,
  getSupplierListAction,
  getItemDetailAction,
  editItemAction,
} from "../Action/action";
import moment from "moment";
// let bigInt = require("big-integer");

const ItemAdd = () => {
  const id = window.location.href.split("/").pop();
  const isEditMode = id && id !== "itemAdd";

  const [form, setForm] = useState({
    category_id: "", // set default blank
    expert_id: "",
    name: "",
    lable: "",
    lable_en: "",
    item_put_on: "1",
    image: "",
    previewImage: "./images/noimage.png",
    gallery: [],
    description: "",
    description_en: "",
    share_quantity: "",
    paymentToken: config.PaymentToken,
    priceInToken: 0,
    tokenName: "",
    tokenSymbol: "",
    tokenDecimal: "",
    last_month_price: "",
    last_month_per: "",
    share_price: "",
    tag: "0",
    tagCustom: "",
    tagCustom_en: "",
    sell_end_date: "",
    last_year_perf: "",
    market_value: "",
    value: [],
    user_email: "",
    user_shares: 0,
    last_performance_value: 0,
    last_performance_period: "one_year",
    pdf: "",
    pdfPreview: "",
    pdfTitle: "",
    market_price: 0,
    purchase_price: "",
    purchase_source: "",
    warehouse_id: "",
    supplier_id: "",
    delivered: false,
    invoice: "",
    invoicePreview: "",
    contabile: "",
    contabilePreview: "",
    paid: false,
    paid_amount: 0,
    warehouse_image: "",
    delivery_document: "",
    warehouse_details: [],
    draft: true,
    order: 0,
    galleryDeleted: [],
  });

  const [categoryList, setCategoryList] = useState([]);
  const [attributesList, setAttributesList] = useState([]);
  const [validatioError, setvalidatioError] = useState({});
  const [saving, setsaving] = useState(false);
  const [expertlist, setexpertlist] = useState([]);
  const [maticToEur, setMaticPrice] = useState(0);
  const [usdInEur, setUsdInEur] = useState(1);
  const [usersList, setUsersList] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [warehouses, setWarehouses] = useState([]);

  const getUsersList = useCallback(async () => {
    let res = await getUsersListAction(form);
    if (res.success) {
      let selectList = res.data.map((el) => {
        return {
          value: el.email,
          label: el.email,
          target: {
            name: "user_email",
            value: el.email,
          },
        };
      });
      setUsersList(selectList);
    }
  }, [form]);

  const getMATICTOEUR = useCallback(async () => {
    const response = await axios.get(`https://api.binance.com/api/v3/ticker/price?symbol=MATICEUR`);
    let maticPrice = parseFloat(response.data.price).toFixed(6);
    setMaticPrice(maticPrice);
  }, []);

  const loadUsdcPrice = useCallback(async () => {
    let usdcPrice = await getUsdcPrice();
    setUsdInEur(usdcPrice.data.value);
  }, []);

  const getcategory = useCallback(async () => {
    let res = await getCategoryListAction();
    if (res.success) {
      setCategoryList(res.data);
    }
  }, []);

  const getexpert = useCallback(async () => {
    let res = await getexpertlistAction();
    if (res.success) {
      setexpertlist(res.data);
    }
  }, []);

  const onPdfChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let pdf = event.target.files[0];
      setForm((old) => {
        return { ...old, pdf: pdf, pdfPreview: "" };
      });
    }
  };

  const loadWarehouseData = useCallback(async () => {
    let res = await getWarehouseListAction();
    let resSupplier = await getSupplierListAction();
    console.log(resSupplier.data);
    console.log(res.data);
    if (res.data.success && resSupplier.success) {
      setWarehouses(res.data.data);
      setSuppliers(resSupplier.data);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getcategory();
        await getexpert();
        await getMATICTOEUR();
        await getUsersList();
        await loadUsdcPrice();
        await loadWarehouseData();

        if (isEditMode) {
          let res = await getItemDetailAction({ item_id: id });
          if (res.success) {
            const data = res.data;

            if (data.attributes?.length > 0) {
              setAttributesList(data.attributes);
            }
            getattributesByid(data.category_id);

            const gallery =
              data.gallery?.map((img) => ({
                id: img.id,
                image: null,
                previewImage: config.imageUrl + img.image_path,
                order: img.order,
              })) || [];

            setForm({
              category_id: data.category_id || "",
              expert_id: data.expert_id || "",
              name: data.name || "",
              lable: data.lable || "",
              lable_en: data.lable_en || "",
              item_put_on: data.item_put_on || "1",
              image: "",
              previewImage: config.imageUrl + data.image || "./images/noimage.png",
              gallery: gallery,
              description: data.description || "",
              description_en: data.description_en || "",
              share_quantity: data.share_quantity || "",
              paymentToken: data.paymentToken || config.PaymentToken,
              share_price: data.share_price || "",
              tag: data.tag || "0",
              tagCustom: data.tagCustom || "",
              tagCustom_en: data.tagCustom_en || "",
              sell_end_date: data.sell_end_date ? moment(data.sell_end_date).format("YYYY-MM-DD") : "",
              market_value: data.market_value || "",
              user_email: data.user_email || "",
              user_shares: data.user_shares || 0,
              last_performance_value: data.last_performance_value || 0,
              last_performance_period: data.last_performance_period || "one_year",
              pdf: "",
              pdfPreview: data.pdfPreview?.file ? config.apiUrl + "/itemPdf/" + data.pdfPreview.file : null,
              pdfTitle: data.pdfPreview?.title,
              market_price: data.market_price || 0,
              warehouse_id: data.warehouse_id || "",
              supplier_id: data.supplier_id || "",
              delivered: Boolean(data.delivered),
              invoice: data.invoice ? config.apiUrl + "/itemPdf/" + data.invoice : null,
              contabile: data.contabile ? config.apiUrl + "/itemPdf/" + data.contabile : null,
              invoicePreview: data.invoice_path || "",
              contabilePreview: data.contabile_path || "",
              paid: Boolean(data.paid),
              paid_amount: data.paid_amount || 0,
              warehouse_image: data.warehouse_image ? config.apiUrl + "/uploads/" + data.warehouse_image : null,
              delivery_document: data.delivery_document ? config.apiUrl + "/itemPdf/" + data.delivery_document : null,
              warehouse_details: data.warehouse_details ? JSON.parse(data.warehouse_details) : [],
              draft: Boolean(data.draft),
              order: data.order || 0,
              galleryDeleted: [],
            });
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [isEditMode, id]);

  const getattributesByid = async (id) => {
    let res = await getAttributebyidListAction({ category_id: id });
    if (res.success) {
      setAttributesList(res.data);
    }
  };

  const inputHandler = async (e) => {
    const { name, value } = e.target;

    if (name === "share_quantity") {
      setForm({ ...form, [name]: value, market_value: value * form.share_price });
    } else if (name === "share_price") {
      setForm({ ...form, [name]: value, market_value: form.share_quantity * value });
    } else {
      setForm({ ...form, [name]: value });
    }
    if (e.target.name === "category_id") {
      getattributesByid(e.target.value);
    }
  };

  const inputHandler1 = (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      return { ...old, [name]: value };
    });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setForm((old) => {
        return { ...old, image: img, previewImage: URL.createObjectURL(img) };
      });
    }
  };

  const onImageGalleryChange = (event, index) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      let gallery = [...form.gallery];
      gallery[index] = {
        image: img,
        previewImage: URL.createObjectURL(img),
      };
      setForm((old) => {
        return { ...old, gallery: gallery };
      });
    }
  };

  function validate() {
    let category_nameError = "";
    let expert_nameError = "";
    let nameError = "";
    let lableError = "";
    let imageError = "";
    let descriptionError = "";
    let share_quantityError = "";
    let share_priceError = "";
    let tagError = "";
    let sell_end_dateError = "";
    let warehouse_idError = "";
    let delivery_documentError = "";
    let invoiceError = "";
    let contabileError = "";
    let paid_amountError = "";

    if (form.category_id === "") {
      category_nameError = "Category is required.";
    }
    if (form.id === "") {
      expert_nameError = "Expert name is required.";
    }
    if (form.name === "") {
      nameError = "Name is required.";
    }
    if (form.lable === "") {
      lableError = "Lable is required.";
    }
    if (form.image === "" && !form.previewImage) {
      imageError = "Image is required.";
    }
    if (form.description === "") {
      descriptionError = "Description is required.";
    }
    if (form.description_en === "") {
      descriptionError = "Description (EN) is required.";
    }
    if (form.share_quantity === "") {
      share_quantityError = "Share quantity is required.";
    }
    if (form.share_price === "") {
      share_priceError = "Price is required.";
    }
    if (form.tag === "" || (form.tag === "0" && form.tagCustom === "")) {
      console.log("Tag is required.");
      tagError = "Tag is required.";
    }
    if (form.sell_end_date === "") {
      sell_end_dateError = "Sell end date is required.";
    }

    if (form.delivered) {
      if (!form.warehouse_id) {
        warehouse_idError = "Warehouse is required when item is delivered.";
      }
      if (!form.delivery_document) {
        delivery_documentError = "Delivery document is required when item is delivered.";
      }
    }

    if (form.paid) {
      if (!form.invoice) {
        invoiceError = "La ricevuta è obbligatoria quando il pagamento è effettuato.";
      }
      if (!form.contabile) {
        contabileError = "La contabile è obbligatoria quando il pagamento è effettuato.";
      }
      if (!form.paid_amount) {
        paid_amountError = "L'importo pagato è obbligatorio quando il pagamento è effettuato.";
      }
    }

    if (
      category_nameError ||
      expert_nameError ||
      nameError ||
      lableError ||
      imageError ||
      descriptionError ||
      share_quantityError ||
      share_priceError ||
      tagError ||
      sell_end_dateError ||
      warehouse_idError ||
      delivery_documentError ||
      invoiceError ||
      contabileError ||
      paid_amountError
    ) {
      setvalidatioError({
        category_nameError,
        expert_nameError,
        nameError,
        lableError,
        imageError,
        descriptionError,
        share_quantityError,
        share_priceError,
        tagError,
        sell_end_dateError,
        warehouse_idError,
        delivery_documentError,
        invoiceError,
        contabileError,
        paid_amountError,
      });
      return false;
    } else {
      setvalidatioError({
        category_nameError,
        nameError,
        lableError,
        imageError,
        descriptionError,
        share_quantityError,
        share_priceError,
        tagError,
        sell_end_dateError,
      });
      return true;
    }
  }

  const processingAlert = async () => {
    let timerInterval;
    Swal.fire({
      title: '<span style="color:red;">Please Wait</span>',
      html: "<b>Processing transaction...</b></br><p>Please do not refresh or close the window</p>",
      timer: 1000000,
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("Transaction done!");
      }
    });
  };

  const closeProcessingAlert = async () => {
    Swal.close();
  };

  const imageUpload = async () => {
    let formData1 = new FormData();
    if (form.image) {
      formData1.append("file", form.image);
    } else if (form.previewImage) {
      // Convert image URL to base64
      try {
        const response = await fetch(form.previewImage);
        const blob = await response.blob();
        const base64 = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });

        // Convert base64 to file
        const byteString = atob(base64.split(",")[1]);
        const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const file = new File([ab], "image.jpg", { type: mimeString });
        formData1.append("file", file);
      } catch (error) {
        console.error("Error converting image:", error);
        return;
      }
    }

    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
    /*
    let resIPF = await axios.post(url, formData1, {
      headers: {
        "Content-Type": `multipart/form-data; boundary= ${formData1._boundary}`,
        "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJiYzNhMGNjZC1hMDAyLTRhZjItOTFhYS0wOTU5YzU3MmJhMTAiLCJlbWFpbCI6ImluZm9AY29sbGVjdG9hcHAuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjEsImlkIjoiRlJBMSJ9LHsiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjEsImlkIjoiTllDMSJ9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6IjNkMWZmZGJmZWZiOWE5NmUyZjgzIiwic2NvcGVkS2V5U2VjcmV0IjoiNzZjNTM1MGY1ODA5N2JjYzBmZTBiMzBjM2M1NDUxMjk5ZjQ3MjkwNjUxMWRhMzcwMzk0MDgxYmI1YWYxYTYxYyIsImV4cCI6MTc2MjQ0Njk1MH0.hM66xrI5ZF9h5h6d8hd97c4Vl6DU-ACUfhuHirHoTAQ`,

         "pinata_api_key": "b5149f3bd2763b83d7fb",
        "pinata_secret_api_key": "30b5d1de54b60b82c314d8a61486c6ad6a2f1bc6c2881deac21180f17df64021", 
      },
    });*/

    let resIPF = await axios.post(url, formData1, {
      headers: {
        "Content-Type": `multipart/form-data; boundary= ${formData1._boundary}`,
        pinata_api_key: "b5149f3bd2763b83d7fb",
        pinata_secret_api_key: "30b5d1de54b60b82c314d8a61486c6ad6a2f1bc6c2881deac21180f17df64021",
      },
    });
    console.log(resIPF.data);
    let ipfsImg = resIPF.data.IpfsHash;
    let data = {
      name: form.name,
      description: form.description,
      image: `ipfs://${ipfsImg}`,
    };
    let jsonData = JSON.stringify(data);
    let formData2 = new FormData();
    formData2.append("file", new Blob([jsonData], { type: "application/json" }));
    let resMetadataIPFS = await axios.post(url, formData2, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData2._boundary}`,
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJiYzNhMGNjZC1hMDAyLTRhZjItOTFhYS0wOTU5YzU3MmJhMTAiLCJlbWFpbCI6ImluZm9AY29sbGVjdG9hcHAuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjEsImlkIjoiRlJBMSJ9LHsiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjEsImlkIjoiTllDMSJ9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6IjNkMWZmZGJmZWZiOWE5NmUyZjgzIiwic2NvcGVkS2V5U2VjcmV0IjoiNzZjNTM1MGY1ODA5N2JjYzBmZTBiMzBjM2M1NDUxMjk5ZjQ3MjkwNjUxMWRhMzcwMzk0MDgxYmI1YWYxYTYxYyIsImV4cCI6MTc2MjQ0Njk1MH0.hM66xrI5ZF9h5h6d8hd97c4Vl6DU-ACUfhuHirHoTAQ`,
        /*  "pinata_api_key": "b5149f3bd2763b83d7fb",
        "pinata_secret_api_key": "30b5d1de54b60b82c314d8a61486c6ad6a2f1bc6c2881deac21180f17df64021", */
      },
    });
    let ipfsMetadataHash = resMetadataIPFS.data.IpfsHash;
    console.log(`Metadata uploaded to IPFS with hash: ${ipfsMetadataHash}`);
    return ipfsMetadataHash;
    // await setItemUri();
  };

  const submitForm = async (e, draft = true) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) return;

    await loadUsdcPrice();

    try {
      processingAlert();
      if (!isEditMode || (isEditMode && form.draft)) {
        let ipfsImg = await imageUpload();

        const sharePriceBig = new BigNumber(form.share_price);
        const usdInEurBig = new BigNumber(usdInEur);
        const shareQuantityBig = new BigNumber(form.share_quantity);
        const share_usdc_price = sharePriceBig.multipliedBy(usdInEurBig).multipliedBy(10 ** 6);
        const marketValue = shareQuantityBig.multipliedBy(sharePriceBig);
        const minimumTarget = shareQuantityBig.multipliedBy(share_usdc_price).toFixed(0, BigNumber.ROUND_DOWN);

        form.usdc_price = usdInEur;
        form.market_value = marketValue.toString();

        console.log(parseInt(new Date(form.sell_end_date).valueOf() / 1000));
        console.log(parseInt(new Date(new Date(form.sell_end_date).setMonth(new Date(form.sell_end_date).getMonth() + 2)).valueOf() / 1000));

        let trx_data = {
          uri: `ipfs://${ipfsImg}`,
          maxShares: parseInt(form.share_quantity),
          preSaleStart: parseInt(new Date().getTime() / 1000),
          preSaleEnd: parseInt(new Date(new Date(form.sell_end_date).setMonth(new Date(form.sell_end_date).getMonth() + 2)).valueOf() / 1000),
          minimumTarget: minimumTarget.toString(),
          sharePrice: share_usdc_price.toFixed(0),
          paymentToken: form.paymentToken,
        };

        form.trx_data = JSON.stringify(trx_data);
      }
      form.draft = draft;

      // Add galleryOrder array with ordered image IDs for existing images
      form.galleryOrder = JSON.stringify(form.gallery
        .map((img, index) => [img.id ? img.id : null, index])
        .filter(img => img[0] !== null));

      // Add newGalleryOrder array with indexes for new images
      form.newGalleryOrder = form.gallery
        .map((img, index) => ({ img, index }))
        .filter(({ img }) => !img.id)
        .map(({ index }) => index);

      if (form.warehouse_details?.length > 0) {
        form.warehouse_details = JSON.stringify(form.warehouse_details);
      }

      let atData = [];
      $(".attr").each(function (index) {
        let b = {
          attribute_id: $(this).attr("data-attr_id"),
          value: $(this).val(),
        };
        atData.push(b);
      });
      form.attribute_data = JSON.stringify(atData);

      let formData = new FormData();
      Object.keys(form).forEach((key) => {
        if (key === "gallery") {
          console.log(form[key]);
          form[key].forEach((item) => {
            if (item.image && item.image instanceof File) {
              formData.append("gallery", item.image);
            }
          });
        } else if (["image", "pdf", "invoice", "contabile", "warehouse_image", "delivery_document"].includes(key)) {
          if (form[key] && form[key] instanceof File) {
            formData.append(key, form[key]);
          }
        } else {
          formData.append(key, form[key]);
        }
      });
      formData.append('galleryDeleted', JSON.stringify(form.galleryDeleted));

      if (isEditMode) {
        formData.append("item_id", id);
      }

      let res = isEditMode ? await editItemAction(formData) : await addItemAction(formData);

      if (res.success) {
        toast.success(res.msg);
        closeProcessingAlert();
        setsaving(false);
        setTimeout(() => {
          window.location.href = `${config.baseUrl}itemList`;
        }, 1200);
      } else {
        closeProcessingAlert();
        toast.error(res.msg);
        return false;
      }
    } catch (err) {
      console.error(err);
      closeProcessingAlert();
      if (err.code === 4001) {
        toast.error("Transaction rejected!");
      } else {
        toast.error("Transaction error: " + err.message);
      }
      return false;
    }
  };

  const onInvoiceChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      setForm((old) => {
        return { ...old, invoice: file, invoicePreview: "" };
      });
    }
  };

  const onContabileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      setForm((old) => {
        return { ...old, contabile: file, contabilePreview: "" };
      });
    }
  };

  const onWarehouseImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setForm((old) => {
        return { ...old, warehouse_image: img };
      });
    }
  };

  const onDeliveryDocumentChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let doc = event.target.files[0];
      setForm((old) => {
        return { ...old, delivery_document: doc };
      });
    }
  };

  const moveGalleryImage = (currentIndex, direction) => {
    const newIndex = direction === 'left' ? currentIndex - 1 : currentIndex + 1;
    if (newIndex < 0 || newIndex >= form.gallery.length) return;

    const newGallery = [...form.gallery];
    const temp = { ...newGallery[currentIndex] };
    newGallery[currentIndex] = { ...newGallery[newIndex] };
    newGallery[newIndex] = temp;

    setForm(prevForm => ({
      ...prevForm,
      gallery: newGallery
    }));
  };

  return (
    <>
      <div className="wrapper">
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">{isEditMode ? "Modifica Item" : "Aggiungi Nuovo Item"}</h4>
                      <a className="text-primary" href={`${config.baseUrl}itemList`}>
                        <i className="fa fa-arrow-left"></i> Back
                      </a>
                    </div>
                    <div className="row mt-20 mb-50">
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Category</label>
                            <div className="col-md-12">
                              <select className="form-control" name="category_id" value={form.category_id} onChange={inputHandler}>
                                <option value="">-Select Category-</option>
                                {categoryList.map((item) => (
                                  <option value={item.id}>{item.category_name}</option>
                                ))}
                              </select>
                            </div>
                            <span className="validationErr">{validatioError.category_nameError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Expert List</label>
                            <div className="col-md-12">
                              <select className="form-control" name="expert_id" value={form.expert_id} onChange={inputHandler1}>
                                <option value="">-Select Expert List-</option>
                                {expertlist.map((item) => (
                                  <option value={item.id}>{item.expert_name}</option>
                                ))}
                              </select>
                            </div>
                            <span className="validationErr">{validatioError.expert_nameError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Name</label>
                            <div className="col-md-12">
                              <input className="form-control" type="text" autoComplete="off" name="name" value={form.name} onChange={inputHandler} placeholder="Item Name" />
                            </div>
                            <span className="validationErr">{validatioError.nameError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Lable</label>
                            <div className="col-md-12">
                              <input className="form-control" type="text" autoComplete="off" name="lable" value={form.lable} onChange={inputHandler} placeholder="Lable" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Lable (EN)</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="lable_en"
                                value={form.lable_en}
                                onChange={inputHandler}
                                placeholder="Lable (EN)"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                        <div className="col-md-4" />

                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Tag</label>
                            <div className="col-md-12">
                              <select className="form-control" name="tag" value={form.tag} onChange={inputHandler}>
                                <option value={0}>Custom Tag</option>
                                <option value={1}>Best Seller</option>
                                <option value={2} selected>
                                  New Item
                                </option>
                                <option value={3}>Holding Period 1 year</option>
                                <option value={4}>Holding Period 2 years</option>
                                <option value={5}>Holding Period 3 years</option>
                                <option value={6}>Holding Period 4 years</option>
                                <option value={7}>Holding Period 5 years</option>
                              </select>
                            </div>
                          </div>
                          {(form.tag !== 0 || form.tag !== "0") && <span className="validationErr">{validatioError.tagError}</span>}
                        </div>
                        <div className="col-md-2" />
                      </div>
                      {(form.tag === 0 || form.tag === "0") && (
                        <>
                          <div className="row">
                            <div className="col-md-6" />
                            <div className="col-md-4">
                              <div className="form-group row mb-1">
                                <label className="col-form-label col-md-12">Custom Tag</label>
                                <div className="col-md-12">
                                  <input
                                    className="form-control"
                                    type="text"
                                    autoComplete="off"
                                    name="tagCustom"
                                    value={form.tagCustom}
                                    onChange={inputHandler}
                                    placeholder="Custom Tag"
                                  />
                                </div>
                              </div>
                              <span className="validationErr">{validatioError.tagError}</span>
                            </div>
                            <div className="col-md-2" />
                          </div>
                          <div className="row">
                            <div className="col-md-6" />
                            <div className="col-md-4">
                              <div className="form-group row mb-1">
                                <label className="col-form-label col-md-12">Custom Tag (EN)</label>
                                <div className="col-md-12">
                                  <input
                                    className="form-control"
                                    type="text"
                                    autoComplete="off"
                                    name="tagCustom_en"
                                    value={form.tagCustom_en}
                                    onChange={inputHandler}
                                    placeholder="Custom Tag (EN)"
                                  />
                                </div>
                              </div>
                              <span className="validationErr">{validatioError.tagError}</span>
                            </div>
                            <div className="col-md-2" />
                          </div>
                        </>
                      )}
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">ItemPutOn</label>
                            <div className="col-md-12">
                              <select className="form-control" name="item_put_on" value={form.item_put_on} onChange={inputHandler}>
                                <option value={1}>Pre-order</option>
                                {/* <option value={2}>Marketplace</option> */}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Sell End Date</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="date"
                                autoComplete="off"
                                name="sell_end_date"
                                value={form.sell_end_date}
                                onChange={inputHandler}
                                placeholder="Sell end date"
                              />
                            </div>
                            <span className="validationErr">{validatioError.sell_end_dateError}</span>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Image</label>
                            <div className="col-md-12">
                              <input className="form-control mb-2" type="file" name="image" onChange={onImageChange} />
                              <img alt="" src={form.previewImage} width="100px" height="100px"></img>
                            </div>
                            <span className="validationErr">{validatioError.imageError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group">
                            <label className="col-form-label">Galleria Immagini</label>
                            <div 
                              className="gallery-container" 
                              style={{ 
                                maxWidth: '800px',
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '10px'
                              }}
                            >
                              {form.gallery.map((val, key) => (
                                <div 
                                  className="border rounded p-2" 
                                  key={key}
                                  style={{ 
                                    width: '250px',
                                    display: 'flex',
                                    flexDirection: 'column'
                                  }}
                                >
                                  <img 
                                    alt="" 
                                    src={form.gallery[key].previewImage ?? ""} 
                                    style={{
                                      width: '100%',
                                      height: '150px',
                                      objectFit: 'cover',
                                      marginBottom: '10px'
                                    }}
                                  />
                                  <input 
                                    className="form-control form-control-sm mb-2" 
                                    type="file" 
                                    onChange={(e) => onImageGalleryChange(e, key)} 
                                    key={`gallery-input-${key}-${form.gallery[key].image?.name || 'empty'}`}
                                  />
                                  <div className="btn-group">
                                    <button 
                                      type="button"
                                      className="btn btn-sm btn-primary"
                                      disabled={key === 0}
                                      onClick={() => moveGalleryImage(key, 'left')}
                                    >
                                      <i className="fa fa-arrow-left"></i>
                                    </button>
                                    <button 
                                      type="button"
                                      className="btn btn-sm btn-primary"
                                      disabled={key === form.gallery.length - 1}
                                      onClick={() => moveGalleryImage(key, 'right')}
                                    >
                                      <i className="fa fa-arrow-right"></i>
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-danger"
                                      onClick={() => {
                                        let gallery = form.gallery;
                                        const deletedImage = form.gallery[key];
                                        
                                        if (deletedImage.id) {
                                          setForm(prev => ({
                                            ...prev,
                                            galleryDeleted: [...prev.galleryDeleted, deletedImage.id]
                                          }));
                                        }
                                        
                                        gallery = gallery.filter((el) => el !== form.gallery[key]);
                                        setForm(prev => ({
                                          ...prev,
                                          gallery: gallery
                                        }));
                                      }}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <button
                              type="button"
                              className="btn btn-primary mt-3"
                              onClick={() =>
                                setForm({
                                  ...form,
                                  gallery: [
                                    ...form.gallery,
                                    {
                                      previewImage: "./images/noimage.png",
                                      image: "",
                                    },
                                  ],
                                })
                              }
                            >
                              <i className="fa fa-plus"></i> Aggiungi immagine
                            </button>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Fact sheet (pdf)</label>
                            <div className="col-md-12">
                              <input className="form-control mb-2" type="file" name="pdf" onChange={onPdfChange} />
                              {form.pdfPreview && (
                                <a className=" btn btn-primary" rel="noreferrer" target="_blank" href={form.pdfPreview}>
                                  Go to pdf
                                </a>
                              )}
                              <label className="col-form-label col-md-12">Fact sheet title</label>
                              <input
                                className="form-control mb-2"
                                type="text"
                                name="pdfTitle"
                                value={form.pdfTitle}
                                onChange={(e) => setForm({ ...form, pdfTitle: e.target.value })}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Short Description</label>
                            <div className="col-md-12">
                              <textarea className="form-control" value={form.description} name="description" onChange={inputHandler} placeholder="Description"></textarea>
                            </div>
                            <span className="validationErr">{validatioError.descriptionError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Short Description (EN)</label>
                            <div className="col-md-12">
                              <textarea
                                className="form-control"
                                value={form.description_en}
                                name="description_en"
                                onChange={inputHandler}
                                placeholder="Description (EN)"
                              ></textarea>
                            </div>
                            <span className="validationErr">{validatioError.descriptionError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Share Quantity</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="share_quantity"
                                value={form.share_quantity}
                                onChange={inputHandler}
                                placeholder="Share Quantity"
                                readOnly={!form.draft}
                              />
                            </div>
                            <span className="validationErr">{validatioError.share_quantityError}</span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Share Price {`( Matic Price : ${parseFloat(form.share_price / maticToEur).toFixed(6)} )`}</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="share_price"
                                value={form.share_price}
                                onChange={inputHandler}
                                placeholder="Share Price €"
                                readOnly={!form.draft}
                              />
                            </div>
                            <span className="validationErr">{validatioError.share_priceError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Last Perf. Value %</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="number"
                                autoComplete="off"
                                name="last_performance_value"
                                value={form.last_performance_value}
                                onChange={inputHandler}
                                placeholder="Last perf."
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Last Perf. period</label>
                            <div className="col-md-12">
                              <select className="form-control" name="last_performance_period" value={form.last_performance_period} onChange={inputHandler}>
                                <option value="">Select Period</option>
                                <option value="one_month">1 Month</option>
                                <option value="two_month">2 Months</option>
                                <option value="three_month">3 Months</option>
                                <option value="six_month">6 Months</option>
                                <option value="one_year">1 Year</option>
                                <option value="two_year">2 Years</option>
                                <option value="three_year">3 Years</option>
                                <option value="four_year">4 Years</option>
                                <option value="five_year">5 Years</option>
                                <option value="six_year">6 Years</option>
                                <option value="seven_year">7 Years</option>
                                <option value="eight_year">8 Years</option>
                                <option value="nine_year">9 Years</option>
                                <option value="ten_year">10 Years</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                        <div className="col-md-2" />

                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Market Value</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="market_value"
                                value={form.market_value}
                                onChange={inputHandler}
                                placeholder="Market value"
                                readOnly={!form.draft}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Real Market Price</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="number"
                                autoComplete="off"
                                name="market_price"
                                value={form.market_price}
                                onChange={inputHandler}
                                placeholder="Market price"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row mt-4 mb-4">
                        <div className="col-md-2" />

                        <div className="col-md-8">
                          <hr style={{ borderColor: "#ffffff1f" }} />
                          <h4 className="box-title mb-4">Gestione Magazzino</h4>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Fornitore</label>
                            <div className="col-md-12">
                              <select className="form-control" name="supplier_id" value={form.supplier_id} onChange={inputHandler}>
                                <option value="">Seleziona fornitore</option>
                                {suppliers.map((supplier) => (
                                  <option key={supplier.id} value={supplier.id}>
                                    {supplier.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Consegnato</label>
                            <div className="col-md-12">
                              <div className="">
                                <input
                                  className="form-check-input d-none"
                                  type="checkbox"
                                  name="delivered"
                                  id="deliveredCheckbox"
                                  checked={Boolean(form.delivered)}
                                  onChange={(e) => {
                                    setForm({ ...form, delivered: e.target.checked ? 1 : 0, warehouse_id: null });
                                  }}
                                />
                                <label className="form-check-label" htmlFor="deliveredCheckbox">
                                  Bene consegnato
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      {form.delivered && (
                        <div className="row">
                          <div className="col-md-2" />
                          <div className="col-md-8">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">
                                Magazzino <span className="text-danger">*</span>
                              </label>
                              <div className="col-md-12">
                                <select className="form-control" name="warehouse_id" value={form.warehouse_id} onChange={inputHandler} required>
                                  <option value="">Seleziona magazzino</option>
                                  {warehouses.map((warehouse) => (
                                    <option key={warehouse.id} value={warehouse.id}>
                                      {warehouse.name}
                                    </option>
                                  ))}
                                </select>
                                <span className="validationErr">{validatioError.warehouse_idError}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2" />
                        </div>
                      )}

                      {form.delivered && (
                        <div className="row">
                          <div className="col-md-2" />
                          <div className="col-md-4">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Immagine magazzino</label>
                              <div className="col-md-12">
                                <input className="form-control mb-2" type="file" name="warehouse_image" accept="image/*" onChange={onWarehouseImageChange} />
                                {form.warehouse_image && typeof form.warehouse_image === "string" && (
                                  <a className="btn btn-primary" href={form.warehouse_image} target="_blank" rel="noreferrer">
                                    Visualizza immagine magazzino
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">
                                Bolla di consegna <span className="text-danger">*</span>
                              </label>
                              <div className="col-md-12">
                                <input className="form-control mb-2" type="file" name="delivery_document" accept=".pdf" onChange={onDeliveryDocumentChange} required />
                                <span className="validationErr">{validatioError.delivery_documentError}</span>
                                {form.delivery_document && typeof form.delivery_document === "string" && (
                                  <a className="btn btn-primary" rel="noreferrer" target="_blank" href={form.delivery_document}>
                                    Visualizza documento di consegna
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row" style={{ display: "none" }}>
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Receive Token</label>
                            <div className="col-md-12">
                              <select className="form-control" name="paymentToken" onChange={inputHandler} value={form.paymentToken}>
                                <option value={config.ZERO_ADDRESS}>MATIC - {config.ZERO_ADDRESS} </option>
                                <option value={config.PaymentToken}>USDT - {config.PaymentToken}</option>
                                <option value={config.PaymentToken2}>USDC - {config.PaymentToken2}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Pagato</label>
                            <div className="col-md-12">
                              <div className="">
                                <input
                                  className="form-check-input d-none"
                                  type="checkbox"
                                  name="paid"
                                  id="paidCheckbox"
                                  checked={Boolean(form.paid)}
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      paid: e.target.checked ? 1 : 0,
                                      paid_amount: e.target.checked ? form.paid_amount : 0, // Reset amount if unchecked
                                    });
                                  }}
                                />
                                <label className="form-check-label" htmlFor="paidCheckbox">
                                  Pagamento effettuato
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {Boolean(form.paid) && (
                          <div className="col-md-4">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">
                                Importo pagato <span className="text-danger">*</span>
                              </label>
                              <div className="col-md-12">
                                <input
                                  className="form-control"
                                  type="number"
                                  step="0.01"
                                  autoComplete="off"
                                  name="paid_amount"
                                  value={form.paid_amount}
                                  onChange={inputHandler}
                                  placeholder="Importo pagato"
                                  required
                                />
                                <span className="validationErr">{validatioError.paid_amountError}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {Boolean(form.paid) && (
                        <div className="row">
                          <div className="col-md-2" />
                          <div className="col-md-4">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">
                                Ricevuta <span className="text-danger">*</span>
                              </label>
                              <div className="col-md-12">
                                <input className="form-control mb-2" type="file" accept=".pdf" name="invoice" onChange={onInvoiceChange} required />
                                <span className="validationErr">{validatioError.invoiceError}</span>
                                {form.invoice && typeof form.invoice == "string" && (
                                  <a className="btn btn-primary" rel="noreferrer" target="_blank" href={form.invoice}>
                                    Visualizza ricevuta
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">
                                Contabile <span className="text-danger">*</span>
                              </label>
                              <div className="col-md-12">
                                <input className="form-control mb-2" type="file" accept=".pdf" name="contabile" onChange={onContabileChange} required />
                                <span className="validationErr">{validatioError.contabileError}</span>
                                {form.contabile && typeof form.contabile == "string" && (
                                  <a className="btn btn-primary" rel="noreferrer" target="_blank" href={form.contabile}>
                                    Visualizza contabile
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2" />
                        </div>
                      )}

                      <div className="row mt-4 mb-4">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <hr style={{ borderColor: "#ffffff1f" }} />
                          <h4 className="box-title mb-4">Etichette magazzino</h4>

                          {form.warehouse_details?.map((detail, index) => (
                            <div className="row mb-3" key={index}>
                              <div className="col-md-5">
                                <div className="form-group">
                                  <label className="col-form-label">ID Etichetta</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={detail.id || ""}
                                    onChange={(e) => {
                                      const newDetails = [...form.warehouse_details];
                                      newDetails[index] = { ...newDetails[index], id: e.target.value };
                                      setForm((prev) => ({ ...prev, warehouse_details: newDetails }));
                                    }}
                                    placeholder="ID Magazzino"
                                  />
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="form-group">
                                  <label className="col-form-label">Descrizione lotto</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={detail.name || ""}
                                    onChange={(e) => {
                                      const newDetails = [...form.warehouse_details];
                                      newDetails[index] = { ...newDetails[index], name: e.target.value };
                                      setForm((prev) => ({ ...prev, warehouse_details: newDetails }));
                                    }}
                                    placeholder="Nome Magazzino"
                                  />
                                </div>
                              </div>
                              <div className="col-md-2 d-flex align-items-end">
                                <button
                                  type="button"
                                  className="btn btn-danger mb-3"
                                  onClick={() => {
                                    const newDetails = form.warehouse_details.filter((_, i) => i !== index);
                                    setForm((prev) => ({ ...prev, warehouse_details: newDetails }));
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          ))}

                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              const newDetails = [...(form.warehouse_details || []), { id: "", name: "" }];
                              setForm((prev) => ({ ...prev, warehouse_details: newDetails }));
                            }}
                          >
                            <i className="fa fa-plus"></i> Aggiungi Etichetta magazzino
                          </button>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Ordine di visualizzazione</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="number"
                                autoComplete="off"
                                name="order"
                                value={form.order}
                                onChange={inputHandler}
                                placeholder="Ordine di visualizzazione"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      <div className="text-center">
                        <br />
                        <button
                          type="submit"
                          className="btn btn-primary me-2"
                          onClick={(e) => {
                            e.preventDefault();
                            Swal.fire({
                              title: 'Conferma',
                              text: isEditMode 
                                ? "Sei sicuro di voler pubblicare questo bene?"
                                : "Sei sicuro di voler pubblicare questo bene?",
                              icon: 'question',
                              showCancelButton: true,
                              confirmButtonText: isEditMode ? 'Sì, pubblica' : 'Sì, salva',
                              cancelButtonText: 'Annulla',
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33'
                            }).then((result) => {
                              if (result.isConfirmed) {
                                submitForm(e, false);
                              }
                            });
                          }}
                        >
                          {isEditMode ? "Pubblica in app" : "Pubblica in app"}
                        </button>
                        {form.draft && (
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={(e) => {
                              e.preventDefault();
                              submitForm(e, true);
                            }}
                          >
                            Salva come bozza
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default ItemAdd;
